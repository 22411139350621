import { addressFields } from '../defaultValues';

export const headsupfortails = ['headsupfortails', 'headsupfortails-test'];
// export const commentMandatorySubdomain = [
//   'headsupfortails',
//   'headsupfortails-test',
//   'zouk',
//   'shantanunikhil',
//   'shantanunikhil-test',
//   'urturms',
// ];

export const validateRequest = ({ udfKeys, user }) => values => {
  const errors = {};
  if (!values.reasonId) {
    errors.reasonId = 'This is a mandatory field';
  }
  if (udfKeys?.commentMandatory && !values.comment) {
    errors.comment = 'This is a mandatory field';
  }

  if (
    values.reasonId?.subReasons?.length > 0 &&
    !values.subreason &&
    !headsupfortails.includes(user.subdomain)
  ) {
    errors.subreason = 'This is a mandatory field';
  }

  return errors;
};

export const validateLogin = values => {
  const errors = {};
  if (!values.orderId) {
    errors.orderId = 'Required';
  }
  if (!values.mobile) {
    errors.mobile = 'Required';
  }
  return errors;
};

export const validateSelection = values => {
  const errors = {};
  if (!values.selection) {
    errors.selection = 'This is a mandatory field';
  }
  return errors;
};

export const bankFields = [
  'name',
  'accountNumber',
  'verifyAccountNumber',
  'ifsccode',
  'bankName',
  'branchName'
];

export const fieldLabels = {
  bankName: 'bankName',
  branchName: 'branchName',
  accountNumber: 'accountNumber',
  verifyAccountNumber: 'accountNumber',
  ifsccode: 'ifscCode',
  name: 'accountHolderName',
  upiName: 'UPI',
  upiId: 'UPI ID'
};

export const upiFields = ['upiName', 'upiId'];

export const bankFormValidation = bankDetail =>
  bankFields.reduce((prev, curr) => {
    if (!bankDetail[curr]) {
      prev[curr] = 'This is a mandatory field';
    }
    if (['bankName', 'branchName'].includes(curr) && !bankDetail[curr]) {
      prev[curr] = 'This is a mandatory field. Please fetch details';
      if (bankDetail.ifsccode) {
        prev.ifsccode = 'Please enter a valid IFSC code and fetch details';
      }
    }

    if (curr === 'name') {
      if (!bankDetail[curr]?.match(/\b([A-ZÀ-ÿ-,a-z. ']+[ ]*)+/gm)) {
        prev[curr] = 'Please enter a valid name';
      }
    }

    if (['accountNumber', 'verifyAccountNumber'].includes(curr)) {
      if (bankDetail.accountNumber !== bankDetail.verifyAccountNumber) {
        prev.verifyAccountNumber =
          'The account numbers do not match. Please try again.';
      }
      // removing this as not sure what international standards are, can be picked later.
      // if (!bankDetail[curr]?.match(/^\d{9,18}$/)) {
      //   prev[curr] = 'Please enter a valid bank account number';
      // }
    }
    return prev;
  }, {});

export const limitedBankFormValidation = bankDetail => {
  let errorObj = {};
  if (!bankDetail.accountNumber || bankDetail.accountNumber.length !== 24) {
    errorObj.accountNumber = 'IBAN should be 24 characters long';
  }
  if (bankDetail.bankName.length === 0) {
    errorObj.bankName = 'Bank name is a mandatory field';
  }
  return errorObj;
};

export const upiFormValidation = upiDetails =>
  upiFields.reduce((prev, curr) => {
    if (!upiDetails[curr]) {
      prev[curr] = 'This is a mandatory field';
    }
    return prev;
  }, {});

export const addressFormValidation = formValues =>
  addressFields.reduce((prev, curr) => {
    if (!formValues[curr.name] && curr.required) {
      prev[curr.name] = 'This is a mandatory field';
    }
    return prev;
  }, {});

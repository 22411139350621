/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  directToExchangeSummary,
  orderInfoHandler,
  returnResonsHandler
} from '../../service';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ProductSelection from './forms/productSelection';
import MultipleShipments from './MultipleShipments';
import Reasons from './forms/reasons';
import Address from './forms/address';
import DateSelection from './forms/date';
import Summary from './forms/summary/summary';
import Wrapper, { useExchangeContext } from './wrapper';
import Variants from './forms/variants';
import Refund from './forms/refund';
import { isOnlySummaryScreenDomains, steps } from './defaultValues';
import useResize from './utils/useResize';
import NotFound from './NotFoundExchange';
import Catalogue from './Catalogue';
import ExchangePayments from './forms/exchangePayments';
import { zipyInit } from '../../utils/helper';
// import ProductDetails from './Catalogue/ProductDetails';
// import ProductDetails from './Catalogue/ProductDetails';

export const stepWiseHeading = [
  '',
  (returnExchange = 'Return / Exchange', isEligible) =>
    isEligible
      ? `Select an item to ${returnExchange}`
      : `Item(s) already in ${returnExchange}`,
  type => `Why do you want to ${type}?`,
  'Select Variant',
  type =>
    `Select your Pickup ${type === 'exchange' ? '/ Delivery' : ''} address`,
  'Expected pickup date',
  'Refund Options',
  type => ` ${type === 'Replace' ? 'Replacement' : type} Summary`
];

const Order = ({
  user,
  page,
  orderInfoHandler,
  location,
  returnResonsHandler,
  orderInfo,
  ...props
}) => {
  const [isError, setIsError] = useState(false);
  const {
    state,
    setState,
    labels: { exchangeTexts, returnTexts },
    step,
    goForward
  } = useExchangeContext();

  const uiState = useResize();
  // const queryParams = new URLSearchParams(location.search);

  // const setSkipStep = (num = -1) => setState({ ...state, skipStep: num });

  const { search, hash } = location;
  const searchParams = new URLSearchParams(search + hash);

  useEffect(() => {
    const query = {
      key: searchParams.get('security_key'),
      waybill: searchParams.get('waybill'),
      orderId: searchParams.get('order_id') ?? searchParams.get('logOrderId'),
      mobile: searchParams.get('mobile'),
      email: searchParams.get('email'),
      domain: user.subdomain,
      source: searchParams.get('source'),
      token: searchParams.get('token'),
      sku: searchParams.get('sku'),
      awbs: searchParams.get('awbs'),
      exchangeReason: searchParams.get('exchangeReason'),
      variantSku: searchParams.get('variantSku'),
      currSize: searchParams.get('currSize'),
      newSize: searchParams.get('newSize'),
      exchangeLogId: searchParams.get('exchange_log_id'),
      isReturnExchange: true
    };
    setState({
      source: searchParams.get('source'),
      token: searchParams.get('token'),
      sku: searchParams.get('sku')
    });
    if (query.orderId) {
      query.orderId = encodeURIComponent(query.orderId);
    }

    const isOnlySummaryParams =
      query.sku &&
      // query.token &&
      query.key &&
      // query.currSize &&
      query.newSize &&
      query.variantSku &&
      query.exchangeLogId && // @TODO @soham adding exchange log Id here just to show 404 if not present, later needs to be added in payload when apis ready
      query.exchangeReason &&
      searchParams.get('isExchange') === 'true';

    if (
      isOnlySummaryScreenDomains.includes(user.subdomain) &&
      !isOnlySummaryParams
    ) {
      setIsError(true);
    }
    const handlersList = [returnResonsHandler(user.subdomain)];
    const nextStep = isOnlySummaryScreenDomains.includes(user.subdomain)
      ? steps.SUMMARY
      : steps.ITEM_SELECTION;

    if (!orderInfo.loggedUser) {
      Promise.all([...handlersList, orderInfoHandler(query)]).then(res => {
        if (
          res?.[1]?.meta?.status === 200 &&
          isOnlySummaryParams &&
          isOnlySummaryScreenDomains.includes(user.subdomain)
        ) {
          props.directToExchangeSummary(query);
        }

        zipyInit(res?.[1]?.result, user);

        goForward(nextStep);
      });
    } else {
      handlersList[0].then(() => goForward(nextStep));
    }
  }, []);

  const onSelect = (uuid, awb) => {
    if (uuid && awb) {
      // replaced history.push with window.location.assign as history is not reloading ui and therefore not getting correct awb infos
      window.location.assign(
        // history.push
        `/return-exchange?security_key=${uuid}&waybill=${awb}`
      );
    }
  };

  const { hasMultipleShipments } = orderInfo;
  let showMultipleShipments = false;
  if (
    hasMultipleShipments &&
    !searchParams.has('awbs') &&
    !searchParams.has('security_key') &&
    orderInfo.awbs?.length > 1 &&
    orderInfo.shipmentIDs?.length > 1
  ) {
    const awbs = orderInfo.awbs.join(',');
    const shipmentIDs = orderInfo.shipmentIDs.join(',');
    window.location.assign(
      `/return-exchange?awbs=${awbs}&security_key=${shipmentIDs}`
    );
  }
  if (searchParams.has('awbs') && searchParams.has('security_key')) {
    showMultipleShipments = true;
  }
  return (
    <div
      className={`text-left ${user.subdomain} ${
        page.appOnly ? 'webview' : ''
      } bg-brand-primary exchange`}
    >
      <div className="container-fluid">
        <div className="row row-eq-height">
          <div className="col-md-8 col-md-offset-8 col-xs-24">
            {hasMultipleShipments &&
            showMultipleShipments &&
            orderInfo?.products?.length > 0 ? (
              <MultipleShipments
                props={props}
                tracking={props.tracking}
                orderInfo={orderInfo}
                height={uiState.conHeight}
                exchangeText={`${returnTexts?.[0]} / ${exchangeTexts?.[0]}`}
                onSelect={onSelect}
              />
            ) : (
              <div
                className="row md-flex xs-flex flex-col bg-brand-primary"
                style={{ height: uiState.conHeight }}
              >
                {isError || orderInfo.error ? (
                  <NotFound message="Request parameters are invalid!" />
                ) : (
                  <>
                    {step.current === steps.ITEM_SELECTION && (
                      <div
                        // style={{ height: uiState.conHeight }}
                        className={`col-md-24 ht-100 ${
                          step.current === steps.ITEM_SELECTION
                            ? 'show'
                            : 'hide'
                        } `}
                      >
                        {/* <ProductDetails /> */}
                        {/* <Catalogue /> */}
                        <ProductSelection />
                        {/* <ExchangePayments type={'payment'} /> */}
                      </div>
                    )}
                    {step.current === steps.EXCHANGE_PAYMENTS && (
                      <div
                        // style={{ height: uiState.conHeight }}
                        className={`col-md-24 ht-100 xs-pd-0 ${
                          step.current === steps.EXCHANGE_PAYMENTS
                            ? 'show'
                            : 'hide'
                        } `}
                      >
                        <ExchangePayments type={'payment'} />
                      </div>
                    )}
                    {step.current === steps.REASON && (
                      <div className={`col-md-24 ht-100 xs-pd-0`}>
                        <Reasons
                          selectedProdId={state.editProductId}
                          location={location}
                        />
                      </div>
                    )}{' '}
                    {step.current === steps.VARIANT_EXCHANGE && (
                      <div
                        className={`col-md-24 ht-100 xs-pd-0 ${
                          step.current === steps.VARIANT_EXCHANGE
                            ? 'show'
                            : 'hide'
                        } `}
                      >
                        <Variants />
                      </div>
                    )}
                    {step.current === steps.PRODUCT_EXCHANGE && (
                      <div
                        className={`col-md-24 ht-100 xs-pd-0 ${
                          step.current === steps.PRODUCT_EXCHANGE
                            ? 'show'
                            : 'hide'
                        } `}
                      >
                        <Catalogue />
                      </div>
                    )}
                    {step.current === steps.ADDRESS && (
                      <div
                        className={`col-md-24 ht-100 xs-pd-0 ${
                          step.current === steps.ADDRESS ? 'show' : 'hide'
                        } `}
                      >
                        <Address />
                      </div>
                    )}
                    {step.current === steps.DATE && (
                      <div
                        className={`col-md-24 ht-100 xs-pd-0 ${
                          step.current === steps.DATE ? 'show' : 'hide'
                        } `}
                      >
                        <DateSelection />
                      </div>
                    )}
                    {step.current === steps.REFUND && (
                      <div
                        className={`col-md-24 ht-100 xs-pd-0 ${
                          step.current === steps.REFUND ? 'show' : 'hide'
                        } `}
                      >
                        <Refund />
                      </div>
                    )}
                    {step.current === steps.SUMMARY && (
                      <div
                        className={`col-md-24 ht-100 xs-pd-0 ${
                          step.current === steps.SUMMARY ? 'show' : 'hide'
                        } `}
                      >
                        <Summary />
                      </div>
                    )}
                  </>
                )}{' '}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Order.propTypes = {
  user: PropTypes.object,
  tracking: PropTypes.object,
  orderInfo: PropTypes.object,
  page: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  orderInfoHandler: PropTypes.func,
  returnResonsHandler: PropTypes.func,
  reasons: PropTypes.object,
  variants: PropTypes.object,
  directToExchangeSummary: PropTypes.func
};

Order.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  tracking: state.tracking,
  orderInfo: state.orderInfo,
  page: state.page,
  reasons: state.reasons,
  variants: state.variants
});

const mapDispatchToProps = dispatch => ({
  orderInfoHandler: query => dispatch(orderInfoHandler(query)),
  returnResonsHandler: domain => dispatch(returnResonsHandler(domain)),
  directToExchangeSummary: query => dispatch(directToExchangeSummary(query))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Wrapper(Order))
);

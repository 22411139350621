/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import Select from 'react-select';
import {
  fetchIfscDetail,
  resetBankDetail,
  selectRefundMethod,
  setStoreCreditInfo,
  toggleBankFormValidation,
  updateBankDetail,
  updateStoreCreditInfo,
  updateUPIDetails
} from '../../../service';
import { strings } from '../../../utils/localizedStrings';
import Input from '../../../staticComponents/input';
import { noBackToSrcDomains, subdomains } from '../../return/forms/bankDetail';
import {
  bankDetailNotRequiredForAdminDomains,
  stepActions,
  steps
} from '../defaultValues';
import {
  bankFields,
  bankFormValidation,
  upiFields,
  upiFormValidation
} from './validate';
import { refundPriceBreakupMappings } from '../../../utils/entity_mapping';
import { useExchangeContext } from '../wrapper';
import Instructions from '../components/Instructions';
import { newRefundTypes, refundTypeCodes } from '../../return/defaultValues';

const RefundOptions = ({
  summary,
  orderInfo,
  user,
  tracking,
  bankDetail,
  upiDetails,
  editbutton,
  returnConfig,
  ...props
}) => {
  const [state, setState] = useState({ showRefundBreakup: false });
  const [selectedMethod, setSelectedMethod] = useState('');
  const { step, goBack, goForward } = useExchangeContext();
  const [formErrors, setFormErrors] = useState({});
  const { lang } = user;
  const { meta_data, udfKeys } = tracking.theme;
  const {
    cod_options,
    prepaid_options,
    show_refund_options_as_config = false
  } = returnConfig.refundConfig;
  const {
    orderType,
    confirmedRefundPayment,
    request,
    enterpriseChargesConfig
  } = orderInfo;

  const {
    capture_bank_detail: bankDetailEnabled,
    store_credit_enabled: storeCreditEnabled,
    capture_upi_detail: upiDetailsEnabled,
    enable_payout_link: payoutLinkEnabled,
    enable_discount_coupon: discountCouponEnabled,
    enable_gift_card: giftCardEnabled
  } = meta_data;
  let methodsAllowed = [];
  let options = orderType === 'COD' ? cod_options : prepaid_options;

  if (orderInfo?.isExchangeForwardOrder) {
    options = cod_options;
  }

  if (!show_refund_options_as_config) {
    options = [];
  }

  const refundTypeObj = newRefundTypes(lang);
  if (orderType === 'COD' || orderInfo?.isExchangeForwardOrder) {
    if (bankDetailEnabled) methodsAllowed.push(refundTypeObj.bankDetails);
    if (storeCreditEnabled) methodsAllowed.push(refundTypeObj.credits);
    if (upiDetailsEnabled) methodsAllowed.push(refundTypeObj.upiDetails);
  }
  if (orderType === 'PREPAID' && !orderInfo?.isExchangeForwardOrder) {
    if (
      !(
        orderInfo.storeCredit.totalAmountPaid === 0 &&
        orderInfo.storeCredit.totalCredit > 0
      ) &&
      !noBackToSrcDomains.includes(user.subdomain)
    ) {
      methodsAllowed.push(refundTypeObj.source);
    }
    if (storeCreditEnabled) methodsAllowed.push(refundTypeObj.credits);
    // if (upiDetailsEnabled) methodsAllowed.push('upiDetails');
  }

  if (payoutLinkEnabled) {
    methodsAllowed.push(refundTypeObj.REFUND_LINK);
  }
  if (discountCouponEnabled) {
    methodsAllowed.push(refundTypeObj.DISCOUNT_CODE);
  }
  if (giftCardEnabled) {
    methodsAllowed.push(refundTypeObj.GIFT_CARD);
  }

  if (options?.length > 0) {
    methodsAllowed = options;
  }

  if (methodsAllowed?.length >= 1 && selectedMethod === '') {
    setSelectedMethod(methodsAllowed[0].refund_name);
  }

  const _handleChange = e => {
    bankDetail[e.target.name] = e.target.value;
    if (e.target.name === 'ifsccode') {
      bankDetail.bankName = '';
      bankDetail.branchName = '';
    }
    props.updateBankDetail(bankDetail);
  };

  const formatInput = e => {
    bankDetail[e.target.name] = e.target.value.trim();
    props.updateBankDetail(bankDetail);
  };

  const handleChangeUPIForm = e => {
    upiDetails[e.target.name] = e.target.value;
    props.updateUPIDetails(upiDetails);
  };

  const selectChange = e => {
    upiDetails.upiName = e;
    props.updateUPIDetails(upiDetails);
  };

  const formatUPIInputs = e => {
    upiDetails[e.target.name] = e.target.value.trim();
    props.updateUPIDetails(upiDetails);
  };

  const onGoBack = () => goBack(steps.DATE);

  const fetchIfscDetail = () => {
    if (bankDetail.ifsccode && bankDetail.ifsccode.length === 11) {
      props.fetchIfscDetail(bankDetail.ifsccode).then(res => {
        if (res.hasOwnProperty('BRANCH')) {
          bankDetail.bankName = res.BANK;
          bankDetail.branchName = res.BRANCH;
          props.updateBankDetail(bankDetail);
        }
        setFormErrors(bankFormValidation(bankDetail));
      });
    } else {
      setFormErrors(bankFormValidation(bankDetail));
    }
  };

  const brandCreditText = tracking.theme?.meta_data?.udf?.filter(
    item => item.key === 'brandCreditText'
  )?.[0]?.value;

  const onSubmit = e => {
    e?.preventDefault();
    let { storeCredit, isErrorInCOD, confirmedRefundPayment } = orderInfo;
    storeCredit.allInCredit = null;
    isErrorInCOD = false;
    confirmedRefundPayment = selectedMethod;
    if (selectedMethod === refundTypeCodes.credits) {
      storeCredit.allInCredit = true;
    }
    if (
      [refundTypeCodes.bankDetails, refundTypeCodes.upiDetails].includes(
        selectedMethod
      )
    ) {
      isErrorInCOD = true;
    }
    if (selectedMethod === refundTypeCodes.upiDetails) {
      setFormErrors(upiFormValidation(upiDetails));
      if (Object.keys(upiFormValidation(upiDetails)).length > 0) {
        return;
      }
    }

    if (
      selectedMethod === refundTypeCodes.bankDetails &&
      !(
        props.page.source?.toLowerCase() === 'dashboard' &&
        bankDetailNotRequiredForAdminDomains.includes(user.subdomain)
      )
    ) {
      const bankFormErrors = bankFormValidation(bankDetail);
      setFormErrors(bankFormErrors);
      if (Object.keys(bankFormErrors).length > 0) return;
    }
    if (
      props.page.source?.toLowerCase() === 'dashboard' &&
      bankDetailNotRequiredForAdminDomains.includes(user.subdomain)
    ) {
      isErrorInCOD = false;
    }

    props.updateStoreCreditInfo(storeCredit);
    props.toggleBankFormValidation(isErrorInCOD);
    props.selectRefundMethod(confirmedRefundPayment);
    goForward(steps.SUMMARY);
  };

  const renderRefundHtml = (onlyCredit = false) => {
    if (subdomains.indexOf(user.subdomain) === -1) {
      return null;
    }

    if (onlyCredit) {
      return (
        <>
          <p className="mrg-top-10">
            Amount added to credits cannot be encashed
          </p>
          <p className="mrg-top-10">
            Refund will be transferred to {tracking.theme.brandName} Credits
            within 2 business days after product is picked up.
          </p>
        </>
      );
    }

    switch (orderInfo.confirmedRefundPayment) {
      case 'source':
        return (
          <p className="mrg-top-10">
            Refund will be transferred to Original payment source with in 2
            business days after product is picked up.
          </p>
        );
      case 'credit':
        return (
          <>
            <p className="mrg-top-10">
              Amount added to credits cannot be encashed
            </p>
            <p className="mrg-top-10">
              Refund will be transferred to {tracking.theme.brandName} Credits
              within 2 business days after product is picked up.
            </p>
          </>
        );
      default:
        return null;
    }
  };

  const renderBankDetailForm = () => {
    const { lang } = user;
    return (
      <div className="row">
        {/* <div className={`col-md-24 col-xs-24 mrg-btm-10 ${props.orderInfo.storeCredit.allInCredit === false ? 'hide' : ''}`}>
        {strings[lang].bankDetail}
      </div> */}
        <div
          className={`col-md-24 col-xs-24 mrg-btm-15 ${
            orderInfo.storeCredit.allInCredit === false ? 'mrg-top-10' : ''
          }`}
        >
          <div className="label fs-16 fw-600">
            {strings[lang].accountHolderName}
            <span className="mandatory">
              <strong>*</strong>
            </span>
          </div>
          <Input
            value={bankDetail.name}
            name="name"
            className="form-control bg-brand-secondary bd-primary-color text-uppercase"
            onChange={_handleChange}
            maxlength={200}
            onBlur={formatInput}
          />
          {formErrors?.name && (
            <span className=" text-red">{formErrors?.name}</span>
          )}
        </div>
        <div className="col-md-24 col-xs-24 mrg-btm-15">
          <div className="label fs-16 fw-600">
            {strings[lang].accountNumber}
            <span className="mandatory">
              <strong>*</strong>
            </span>
          </div>
          <Input
            value={bankDetail.accountNumber}
            name="accountNumber"
            className="form-control bg-brand-secondary bd-primary-color"
            onChange={_handleChange}
            maxlength={40}
            onBlur={formatInput}
            type="password"
          />
          {formErrors?.accountNumber && (
            <span className=" text-red">{formErrors?.accountNumber}</span>
          )}
        </div>
        <div className="col-md-24 col-xs-24 mrg-btm-15">
          <div className="label fs-16 fw-600">
            {strings[lang].accountNumber}
            <span className="mandatory">
              <strong>*</strong>
            </span>
          </div>
          <Input
            value={bankDetail.verifyAccountNumber}
            name="verifyAccountNumber"
            className="form-control bg-brand-secondary bd-primary-color"
            onChange={_handleChange}
            maxlength={40}
            onBlur={formatInput}
          />
          {formErrors?.verifyAccountNumber && (
            <span className=" text-red">{formErrors?.verifyAccountNumber}</span>
          )}
        </div>
        <div className="col-md-16 col-xs-16 mrg-btm-15">
          <div className="label fs-16 fw-600">
            {strings[lang].ifscCode}
            <span className="mandatory">
              <strong>*</strong>
            </span>
          </div>
          <Input
            value={bankDetail.ifsccode}
            name="ifsccode"
            className="form-control bg-brand-secondary bd-primary-color text-uppercase"
            onChange={_handleChange}
            rows={3}
            maxlength={11}
            onBlur={formatInput}
          />
          {formErrors?.ifsccode && (
            <span className=" text-red">{formErrors?.ifsccode}</span>
          )}
        </div>
        <div className="col-md-8 col-xs-8 mrg-btm-15 pd-top-20 text-right">
          <button
            type="button"
            className="ant-button ghost rounded-pill min-width-75 xs-min-width-60"
            onClick={fetchIfscDetail}
            disabled={bankDetail.ifsccode.length < 1}
          >
            {strings[lang].fetch}
          </button>
        </div>
        <div className="col-md-24 col-xs-24 mrg-btm-15">
          <div className="label fs-16 fw-600">
            {strings[lang].bankName}
            <span className="mandatory">
              <strong>*</strong>
            </span>
          </div>
          <Input
            value={bankDetail.bankName}
            name="bankName"
            className="form-control bg-brand-secondary bd-primary-color"
            maxlength={40}
            readOnly
            onChange={_handleChange}
          />
        </div>
        <div className="col-md-24 col-xs-24 mrg-btm-15">
          <div className="label fs-16 fw-600">
            {strings[lang].branchName}
            <span className="mandatory">
              <strong>*</strong>
            </span>
          </div>
          <Input
            value={bankDetail.branchName}
            name="branchName"
            className="form-control bg-brand-secondary bd-primary-color"
            maxlength={40}
            readOnly
            onChange={_handleChange}
          />
        </div>
      </div>
    );
  };

  const renderUPIForm = () => {
    const { lang } = user;
    return (
      <div className="row">
        <div className={`col-md-24 col-xs-24 mrg-btm-10 mrg-top-10`}>
          <div className="label">
            {strings[lang].upiName}
            <span className="mandatory">
              <strong>*</strong>
            </span>
          </div>
          <Select
            value={upiDetails.upiName}
            name="name"
            onChange={selectChange}
            options={tracking?.theme?.meta_data?.upi_options ?? []}
            placeholder="Select..."
            className="custom-select"
            classNamePrefix="custom-select"
            simpleValue
          />
          {formErrors?.upiName && (
            <div className=" text-red">{formErrors?.upiName}</div>
          )}
          <div className="label mrg-top-10">
            {strings[lang].upiId}
            <span className="mandatory">
              <strong>*</strong>
            </span>
          </div>
          <Input
            value={upiDetails.upiId}
            name="upiId"
            className="form-control"
            onChange={handleChangeUPIForm}
            maxlength={40}
            onBlur={formatUPIInputs}
          />
          {formErrors?.upiId && (
            <div className=" text-red">{formErrors?.upiId}</div>
          )}
        </div>
      </div>
    );
  };

  const returnReasonIds = Object.values(request || {}).map(
    item => item?.reasonId?.value
  );

  const {
    return_charges_type,
    return_charges,
    returns_charges_data,
    applicable_on
  } = enterpriseChargesConfig || {};

  const returnChargesAmount =
    return_charges_type === 'ENTERPRISE_WIDE'
      ? return_charges
      : returns_charges_data?.find(reason =>
          returnReasonIds.includes(reason.reason_id)
        )?.charges ?? 0;

  const returnCharges =
    applicable_on === 'SHIPMENT_LEVEL'
      ? returnChargesAmount
      : (return_charges_type === 'ENTERPRISE_WIDE'
          ? returnReasonIds
          : returnReasonIds.filter(id =>
              returns_charges_data?.some(reason => reason.reason_id === id)
            )
        )?.length * returnChargesAmount;

  const { priceBreakup, priceBreakupParts } = refundPriceBreakupMappings(
    orderInfo,
    returnConfig.refundConfig,
    returnCharges,
    selectedMethod
  );

  const methodContent = {
    [refundTypeCodes.source]: {
      radio: strings[lang].backToSource,
      heading: DOMPurify.sanitize(
        strings[lang].refundTxt.defaultPrepaid(
          orderInfo?.refundAutomationEnabled
            ? priceBreakup?.total
            : orderInfo.storeCredit.totalAmountPaid > 0
            ? orderInfo.storeCredit.totalAmountPaid - (returnCharges ?? 0)
            : orderInfo.totalAmountPaid !== 0
            ? orderInfo.totalAmountPaid
            : orderInfo.totalRefundAmount,
          orderInfo?.currency ?? '',
          udfKeys?.brandName
        )
      ),
      content: renderRefundHtml()
    },
    [refundTypeCodes.bankDetails]: {
      radio: strings[lang].bankAccount,
      heading: summary
        ? 'Refund will be transferred to given bank details'
        : strings[lang].bankDetail,
      content: renderBankDetailForm()
    },
    [refundTypeCodes.credits]: {
      radio:
        udfKeys.brandCreditLabelText ??
        strings[lang].brandCredits(
          tracking.theme.brandName,
          brandCreditText ? brandCreditText : ''
        ),
      heading: DOMPurify.sanitize(
        udfKeys.brandCreditMessage ??
          strings[lang].refundTxt.refundTotalAsCredit(
            orderInfo?.refundAutomationEnabled
              ? priceBreakup?.total
              : orderInfo.storeCredit.totalAmountPaid > 0
              ? orderInfo.storeCredit.totalAmountPaid
              : orderInfo.totalRefundAmount + orderInfo.storeCredit.totalCredit,
            tracking.theme.brandName
          ) +
            ' ' +
            (udfKeys.brandCreditMessageSuffix ?? '')
      ),
      content: renderRefundHtml(
        orderType === 'COD' && !bankDetailEnabled && storeCreditEnabled
      )
    },
    [refundTypeCodes.upiDetails]: {
      radio: strings[lang].upiDetails,
      heading: summary
        ? 'Refund will be transferred to given UPI details'
        : strings[lang].upiDetails,
      content: renderUPIForm()
    },
    [refundTypeCodes.REFUND_LINK]: {
      radio: strings[lang].payoutLink
    },
    [refundTypeCodes.DISCOUNT_CODE]: {
      radio: strings[lang].discountCoupon
    },
    [refundTypeCodes.GIFT_CARD]: {
      radio: strings[lang].giftCard
    },
    MANUAL: {
      radio: 'I will refund manually via other modes'
    }
  };

  if (summary) {
    let refundMethod = confirmedRefundPayment;

    // @TODO check if fine to remove
    // if (
    //   orderType === 'PREPAID' &&
    //   !(
    //     orderInfo.storeCredit.totalAmountPaid === 0 &&
    //     orderInfo.storeCredit.totalCredit > 0
    //   ) &&
    //   !noBackToSrcDomains.includes(user.subdomain)
    // ) {
    //   refundMethod = refundTypeCodes.source;
    // }

    // if (
    //   confirmedRefundPayment === refundTypeCodes.bankDetails &&
    //   orderType === 'COD' &&
    //   bankDetailEnabled &&
    //   Object.values(bankDetail).join('')
    // ) {
    //   refundMethod = refundTypeCodes.bankDetails;
    // }

    if (orderType === 'COD' && user.subdomain === 'zouk') {
      // custom text for zouk
      refundMethod = refundTypeCodes.source;
    }

    return methodContent[refundMethod]?.heading ? (
      <div className="col-md-24 mrg-btm-20 col-xs-24">
        <div className="d-flex justify-between">
          <span className="fw-600 fs-18 mrg-btm-5 text-primary-color">
            Refund Mode
          </span>
          {editbutton}
        </div>
        <div>
          <p
            className="mrg-btm-10 text-secondary-color"
            dangerouslySetInnerHTML={{
              __html: methodContent[refundMethod]?.heading
            }}
          />
        </div>
        {udfKeys?.returnDisclaimer ? (
          <div>
            <p className="pd-top-15">{udfKeys?.returnDisclaimer}</p>
          </div>
        ) : null}
      </div>
    ) : null;
  }

  useEffect(() => {
    const isExchange =
      Object.values(orderInfo.request ?? {})?.[0]?.type === 'exchange';

    if (isExchange && step.current === steps.REFUND) {
      switch (step.action) {
        case stepActions.FORWARD:
          return goForward(steps.SUMMARY);
        case stepActions.REVERSE:
          return goBack(steps.DATE);
      }
    }

    props.setStoreCreditInfo();

    if (
      step.current === steps.REFUND &&
      ((methodsAllowed.length <= 1 && orderType === 'PREPAID') ||
        (methodsAllowed.length === 0 && orderType === 'COD'))
    ) {
      if (step.action === stepActions.REVERSE) {
        return onGoBack();
      }

      if (methodsAllowed.length === 1 && selectedMethod !== '') onSubmit();
      if (methodsAllowed.length === 0) return goForward(steps.SUMMARY);
    }

    return setSelectedMethod(confirmedRefundPayment);
  }, [step.current === steps.REFUND]);

  return (
    <form className="ht-100 d-flex flex-col" onSubmit={onSubmit}>
      <div className="mrg-btm-20 mrg-top-20 xs-pd-left-10 xs-pd-right-10">
        <span
          className={`icon-arrow_back cursor-pointer pull-left mrg-right-10 fs-20`}
          onClick={onGoBack}
        />
        <span className="fs-20 fw-700 text-capitalize">Refund Options</span>
      </div>
      <div className="ht-100 bd-primary bd-1 xs-bd-0 overflow-auto overflow-x-hidden d-flex justify-between flex-col pd-15">
        <div>
          {/* {!selectedMethod && ( */}
          <>
            <p className="label text-black mrg-btm-10">
              {strings[lang].refundTxt.totalAsCreditNew}
              <span className="text-red">*</span>
            </p>
            {methodsAllowed?.map(method => (
              <div
                key={method.refund_name}
                onClick={() => setSelectedMethod(method.refund_name)}
                className={`cursor-pointer md-flex xs-flex ${
                  selectedMethod === method.refund_name ? 'border-theme' : ''
                } pd-15 bd-1 rounded space-y-3 justify-between mrg-btm-10`}
              >
                <div className="md-flex xs-flex">
                  <label className="radio-input small">
                    <input
                      className="mrg-right-10 mrg-top-5 bg-brand-secondary"
                      type="radio"
                      name="refundMode"
                      required
                      checked={method.refund_name === selectedMethod}
                      value={method.refund_name}
                      onChange={() => {
                        setSelectedMethod(method.refund_name);
                        setFormErrors({});
                      }}
                    />
                  </label>
                  <div>
                    <div className="fs-16 fw-600 text-primary-color">
                      {methodContent[method.refund_name]?.radio ??
                        options.find(
                          option => option.refund_name === method.refund_name
                        )?.text}
                    </div>
                    {method.refund_name === selectedMethod &&
                      method.subtext && (
                        <p
                          className={`fs-14 p-3 rounded text-white mrg-top-5 ${
                            method.adjustmentAmount > 0
                              ? 'bg-sucess-dark'
                              : 'bg-warning'
                          }`}
                        >
                          {method.subtext}
                        </p>
                      )}
                    {/* <div className="fs-14 text-secondary-color">
                  {address.address}
                </div> */}
                  </div>
                </div>
              </div>
            ))}
          </>
          {/* // )} */}
          {selectedMethod && methodContent[selectedMethod]?.heading && (
            <section className="bank-form-container pd-top-5">
              <p
                className="mrg-btm-10"
                dangerouslySetInnerHTML={{
                  __html: methodContent[selectedMethod].heading
                }}
              />

              {methodContent[selectedMethod].content}
            </section>
          )}

          {orderInfo.refundAutomationEnabled ? (
            <>
              <div
                onClick={() => {
                  state.showRefundBreakup = !state.showRefundBreakup;
                  setState({
                    ...state
                  });
                }}
                className="text-blue fs-14 mrg-top-5 mrg-btm-5 cursor-pointer"
              >
                {state.showRefundBreakup ? 'Hide' : 'Show'} Refund Amount
                Breakup
                <img
                  src={'/images/chevron_up.svg'}
                  style={
                    state.showRefundBreakup
                      ? {}
                      : {
                          transform: 'rotate(180deg)'
                        }
                  }
                  alt="expand_less"
                  className="mrg-left-5"
                />
              </div>
              {state.showRefundBreakup ? (
                <div className="bg-light-grey pd-top-5">
                  {priceBreakupParts.map(eachPart => (
                    <div
                      key={eachPart.label}
                      className="fs-12 fw-600 d-flex justify-between pd-left-10 pd-right-10 mrg-btm-5"
                    >
                      <span className="text-secondary-color">
                        {eachPart.label}
                      </span>
                      <span className="text-secondary-color">
                        {eachPart.prefix} {priceBreakup?.[eachPart.value]}
                      </span>
                    </div>
                  ))}
                  {returnCharges > 0 && (
                    <div className="fs-12 fw-600 d-flex justify-between pd-left-10 pd-right-10 mrg-btm-5">
                      <span className="text-secondary-color">
                        Return Charge
                      </span>
                      <span className="text-secondary-color">
                        - {returnCharges.toFixed(2)}
                      </span>
                    </div>
                  )}
                  <div className="fs-12 fw-700 d-flex justify-between pd-left-10 pd-right-10 bg-grey pd-5">
                    <span>Total</span>
                    <span>{priceBreakup?.total}</span>
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
        </div>
        {udfKeys.returnRefundInstructions ? (
          <Instructions description={udfKeys.returnRefundInstructions} />
        ) : null}
      </div>
      <div className="row mrg-btm-15">
        <div className="col-md-24 pd-left-20 pd-right-20">
          <button
            key="refund-submit"
            type="submit"
            className={`ant-button rounded-pill full-width${
              (selectedMethod === 'bankDetails' &&
                !bankFields.every(fieldName => bankDetail?.[fieldName]) &&
                !(
                  props.page.source?.toLowerCase() === 'dashboard' &&
                  bankDetailNotRequiredForAdminDomains.includes(user.subdomain)
                )) ||
              (selectedMethod === 'upiDetails' &&
                !upiFields.every(fieldName => upiDetails?.[fieldName]))
                ? ' disable-element'
                : ''
            }`}
          >
            Next
          </button>
        </div>
      </div>
    </form>
  );
};

RefundOptions.propTypes = {
  className: PropTypes.string,
  bankDetail: PropTypes.object,
  upiDetails: PropTypes.object,
  orderInfo: PropTypes.object,
  tracking: PropTypes.object,
  user: PropTypes.object,
  checkPincodeServiceability: PropTypes.func,
  updateBankDetail: PropTypes.func,
  updateUPIDetails: PropTypes.func,
  resetBankDetail: PropTypes.func,
  resetUPIDetails: PropTypes.func,
  fetchIfscDetail: PropTypes.func,
  updateStoreCreditInfo: PropTypes.func,
  toggleBankFormValidation: PropTypes.func,
  selectRefundMethod: PropTypes.func,
  onSubmit: PropTypes.func,
  step: PropTypes.number,
  skipStep: PropTypes.number,
  setSkipStep: PropTypes.func,
  summary: PropTypes.bool,
  page: PropTypes.any,
  editbutton: PropTypes.any,
  setStoreCreditInfo: PropTypes.any,
  returnConfig: PropTypes.any
};

const mapStateToProps = state => ({
  bankDetail: state.orderInfo.bankDetail,
  upiDetails: state.orderInfo.upiDetails,
  orderInfo: state.orderInfo,
  tracking: state.tracking,
  user: state.user,
  page: state.page,
  returnConfig: state.returnConfig
});

const mapDispatchToProps = dispatch => ({
  selectRefundMethod: confirmedRefundPayment =>
    dispatch(selectRefundMethod(confirmedRefundPayment)),
  updateBankDetail: query => dispatch(updateBankDetail(query)),
  resetBankDetail: () => dispatch(resetBankDetail()),
  updateUPIDetails: query => dispatch(updateUPIDetails(query)),
  updateStoreCreditInfo: query => dispatch(updateStoreCreditInfo(query)),
  setStoreCreditInfo: () => dispatch(setStoreCreditInfo()),
  fetchIfscDetail: query => dispatch(fetchIfscDetail(query)),
  toggleBankFormValidation: query => dispatch(toggleBankFormValidation(query))
});

export default connect(mapStateToProps, mapDispatchToProps)(RefundOptions);

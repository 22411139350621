/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';

import { currencyMapper, orderInfo } from '../../utils/entity_mapping';

const MultipleShipments = props => {
  const { shipments } = props.orderInfo;
  const { config } = props.props.tracking.theme;

  const urlParams = {
    waybill: props.orderInfo?.awb ?? '',
    security_key: props.orderInfo?.shipment_uuid ?? ''
  };

  return (
    <div
      className="row md-flex xs-flex flex-col bg-brand-primary"
      id="exchange-con"
      style={{ height: props.height }}
    >
      {/* <div className="col-xs-24 mrg-top-20 mrg-btm-20">
        <span
          className={`icon-arrow_back cursor-pointer pull-left mrg-right-10 fs-20`}
          onClick={props.goBack}
          role="presentation"
        />
        <span className="fs-20 fw-700 text-capitalize">Select Shipment</span>
      </div> */}
      <div className="col-xs-24 mrg-top-20">
        <div className="md-flex xs-flex ht-100 justify-between flex-col">
          <div
            style={{
              height: `calc(100% - 56px)`,
              overflowY: 'auto',
              overflowX: 'hidden'
            }}
          >
            <div className="fw-600 mrg-top-10 fs-18 col-xs-24 pd-0 mrg-left-10">
              Order ID : {props.orderInfo?.reference_number ?? '---'}
            </div>
            {Object.keys(shipments).map(key => (
              <div key={key}>
                <>
                  <div className="pd-btm-10 pd-top-10 pd-left-10 pd-right-10 md-flex xs-flex items-center justify-between">
                    <span className="fw-600">
                      AWB: {shipments[key][0]?.tracking_info__awb ?? ''}{' '}
                    </span>
                    {shipments[key][0] &&
                    shipments[key].some(
                      shipment =>
                        shipment.shipment_eligible_for_return ||
                        shipment.shipment_eligible_for_exchange
                    ) &&
                    shipments[key].some(
                      shipment =>
                        shipment.eligible_quantity_can_be_return > 0 ||
                        shipment.eligible_quantity_can_be_exchange > 0
                    ) &&
                    shipments[key].some(
                      shipment =>
                        !shipment.non_return_reason ||
                        shipment.non_return_reason === '' ||
                        !shipment.non_exchange_reason ||
                        shipment.non_exchange_reason === ''
                    ) ? (
                      <div className={`action`}>
                        <div className="inputGroup">
                          <button
                            className="ant-button ghost rounded-pill compact width-90 xs-width-80"
                            onClick={() =>
                              props.onSelect(
                                shipments[key][0]
                                  ?.tracking_info__shipment_uuid ?? '',
                                shipments[key][0]?.tracking_info__awb ?? ''
                              )
                            }
                          >
                            Start {props.exchangeText}
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {props.orderInfo?.products
                    .filter(
                      product => product.tracking_info__shipment_uuid === key
                    )
                    .filter(
                      product =>
                        !(
                          product.non_exchange_reason ===
                            'Return request already placed' &&
                          product.non_return_reason ===
                            'Return request already placed'
                        )
                    )
                    .map(product => (
                      <div
                        key={product.id}
                        className={`col-xs-24 ${
                          !product.shipment_eligible_for_exchange &&
                          !product.shipment_eligible_for_return
                            ? 'disabled'
                            : ''
                        } space-y-3 bd-primary rounded no-gutter justify-between bd-1 md-flex  pd-btm-15 pd-top-15 xs-flex`}
                      >
                        <div
                          className={`md-flex mrg-0 xs-flex`}
                          style={{ flex: 1 }}
                        >
                          <img
                            src={
                              product?.images || '/images/img-placeholder.svg'
                            }
                            alt="product"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = '/images/img-placeholder.svg';
                            }}
                            width={100}
                            className="mrg-right-10 min-w-100 object-fit-cover img-rounded"
                            height={100}
                          />

                          <div
                            style={{ width: '100%' }}
                            className="md-flex xs-flex flex-col justify-between"
                          >
                            <div>
                              <div
                                className="text-primary-color fw-600 mrg-btm-5"
                                style={{ wordBreak: 'break-all' }}
                              >
                                {product.description}
                              </div>
                              <div className="text-secondary-color fs-14 mrg-btm-5">
                                SKU {product.sku}
                              </div>
                            </div>
                            <div>
                              <div className="text-secondary-color fs-14">
                                {product.id in props.orderInfo.request ? (
                                  <span>
                                    Qty{' '}
                                    {product.id in props.orderInfo.request
                                      ? `${
                                          props.orderInfo.request[product.id]
                                            .quantity
                                        } of`
                                      : ''}{' '}
                                    {product.eligible_quantity_can_be_return}
                                  </span>
                                ) : (
                                  <span>
                                    {product.quantity -
                                      product.eligible_quantity_can_be_return >
                                      0 && (
                                      <p className=" mrg-btm-5">
                                        {/* <span className="mrg-left-10 mrg-right-10 text-theme">
                                    |
                                  </span> */}
                                        Returned Qty{' '}
                                        {product.quantity -
                                          product.eligible_quantity_can_be_return}
                                      </p>
                                    )}
                                    Qty {product.quantity}
                                  </span>
                                )}
                                <span className="mrg-left-10 mrg-right-10 text-theme">
                                  |
                                </span>
                                <span className="fw-700">
                                  {orderInfo.currency ??
                                    currencyMapper(config.country_code)}{' '}
                                  {product.price}
                                </span>
                              </div>
                              {!product.shipment_eligible_for_exchange &&
                                !product.shipment_eligible_for_return && (
                                  <div className="txt-red mrg-top-5 text-secondary-color fw-400 fs-12">
                                    {product.non_exchange_reason ||
                                      product.non_return_reason}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              </div>
            ))}
            {props.orderInfo?.returns?.length > 0 &&
              props.orderInfo?.products?.some(
                product =>
                  !(
                    product.non_exchange_reason ===
                      'Return request already placed' &&
                    product.non_return_reason ===
                      'Return request already placed'
                  )
              ) && (
                <p
                  className="fs-18 fw-600 mrg-btm-10"
                  style={{ marginTop: '150px' }} // temp style @SohamSingh to change it to better way later
                >
                  Item(s) already in Return
                </p>
              )}
            {props.orderInfo?.returns?.map(info => {
              const returnOrExchange =
                info?.list?.[0]?.return_mode === 'REFUND'
                  ? 'Return'
                  : 'Exchange';
              urlParams.item_info_id = info.itemInfoIds;
              urlParams.return_id = info.returnIds;
              return (
                <React.Fragment key={info.returnIds}>
                  <div className="justify-between col-xs-24 pd-0 items-center d-flex mrg-btm-10">
                    {/* <span className="fs-18 fw-600">
                      {' '}
                      {returnOrExchange} ID : {info.returnIds}
                    </span> */}
                    <Link
                      to={`/return-exchange/status?${new URLSearchParams(
                        urlParams
                      ).toString()}`}
                    >
                      <button
                        type="button"
                        className="ant-button ghost compact rounded-pill width-90 xs-width-80"
                      >
                        Track
                      </button>
                    </Link>
                  </div>
                  {info.list.map(product => (
                    <div
                      key={`${product.id}`}
                      className="disabled bd-primary col-xs-24 cursor-pointer space-y-3 rounded no-gutter justify-between bd-1 md-flex  pd-btm-15 pd-top-15 xs-flex"
                    >
                      <img
                        src={product?.images || '/images/img-placeholder.svg'}
                        alt="product"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = '/images/img-placeholder.svg';
                        }}
                        width={100}
                        className="mrg-right-10 min-w-100 object-fit-cover img-rounded"
                        height={100}
                      />
                      <div
                        style={{ width: '100%' }}
                        className="md-flex xs-flex flex-col justify-between"
                      >
                        <div>
                          <div
                            className="text-primary-color fs-16 fw-600 mrg-btm-5"
                            style={{ wordBreak: 'break-all' }}
                          >
                            {product.description}
                          </div>
                          <div className="text-secondary-color fs-14 mrg-btm-5">
                            SKU {product.sku}
                          </div>
                        </div>
                        <div>
                          <div className="mrg-btm-5 fs-14">
                            <span className="text-secondary-color">
                              Returned Qty {product.return_quantity}
                            </span>
                            <span className="mrg-left-10 mrg-right-10 text-theme">
                              |
                            </span>
                            <span className="text-secondary-color fw-700">
                              {orderInfo.currency ??
                                currencyMapper(config.country_code)}{' '}
                              {product.price}
                            </span>
                          </div>

                          <div className="txt-red text-secondary-color fw-400 fs-12">
                            {returnOrExchange} request already placed
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultipleShipments;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { returnHandler } from '../../../../service';
import { connect } from 'react-redux';
import { infoIcon } from '../../utils/svgs';
import { currencyMapper } from '../../../../utils/entity_mapping';
import Refund from '../refund';
import {
  bankDetailNotRequiredForAdminDomains,
  exchangeTypes,
  isOnlySummaryScreenDomains,
  steps
} from '../../defaultValues';
import Nudge from '../../Nudge';
import { useExchangeContext } from '../../wrapper';
import { bankFormValidation } from '../validate';
import { noBackToSrcDomains } from '../../../return/forms/bankDetail';
import toast from 'react-hot-toast';
import actionMessages from '../../../../utils/messages';
import { withRouter } from 'react-router-dom';
import Instructions from '../../components/Instructions';
import { showRazorpay } from './utils';
import CardOverlay from '../../../cardOverlay';
import { PaymentFailedModalBody } from '../../../Payment';
import ReactTooltip from 'react-tooltip';
import { getCatalogueExchangeTotal } from '../../utils/helper';
import { refundTypeCodes } from '../../../return/defaultValues';

const Summary = ({
  orderInfo,
  tracking,
  exchangeConfig,
  user,
  page,
  file,
  history,
  ...props
}) => {
  const searchParams = new URLSearchParams(history.location.search);
  const [state, setState] = useState({ isSubmitting: false });
  const [showModal, setShowModal] = useState(false);
  const [showPreSubmit, setShowPreSubmit] = useState(false);
  const {
    labels: { exchangeTexts, returnTexts },
    goBack
  } = useExchangeContext();
  const { action, addresses } = orderInfo;
  const { config, meta_data, udfKeys } = tracking.theme;
  const selectedAddress = addresses[action.address.select];
  const productList = Object.values(orderInfo.request ?? {});
  const isExchange = productList?.[0]?.type === 'exchange';
  const isPreSubmitModal =
    udfKeys.isPreSubmitModal === 'true' &&
    isExchange &&
    exchangeConfig.instruction;
  const isExchangeEligible =
    productList.filter(p => p.shipment_eligible_for_exchange).length > 0;
  const isDifferentProductExchange =
    productList.filter(p => p.exchangeType === exchangeTypes.DIFFERENT_PRODUCT)
      .length > 0;

  const {
    totalDiffSign,
    totalPriceDiff,
    totalPriceDiffWithDiscount,

    totalShippingPaid
  } = getCatalogueExchangeTotal(orderInfo, exchangeConfig);

  const catalogueEnabled =
    exchangeConfig.catalogueConfig?.service_active &&
    productList[0].exchangeType !== exchangeTypes.SAME_PRODUCT_SAME_VARIANT;

  const {
    catalogueConfig: { percentageDiscount }
  } = exchangeConfig;

  const totalPayment =
    (percentageDiscount && productList[0]?.price_breakup?.discount_percent > 0
      ? totalPriceDiffWithDiscount
      : totalPriceDiff) +
    exchangeConfig?.catalogueConfig?.exchange_request_fees +
    exchangeConfig?.catalogueConfig?.exchange_shipping_fees -
    totalShippingPaid;

  const priceDiffInstructions = {
    '-1': `Exchanged item is cheaper than original item so you will get ${
      user.subdomain === 'palmonas' ? 'store Credit' : 'refund'
    } of ${orderInfo.currency} ${Math.abs(totalPayment).toFixed(
      2
    )} after placing the exchange.`,
    '0': 'Exchanged item is same price as original item.',
    '1':
      orderInfo.exchange.paymentMode === 'PREPAID'
        ? `Exchange item costs more than the original item. Please pay ${
            orderInfo.currency
          } ${Math.abs(totalPayment).toFixed(2)} to continue with the exchange.`
        : `Exchange item costs more than the original item. You need to pay ${
            orderInfo.currency
          } ${Math.abs(totalPayment).toFixed(
            2
          )} upon the delivery of the exchanged item.`
  };
  const priceDiffResult = {
    '-1': 'LOWER',
    '0': 'EQUAL',
    '1': 'HIGHER'
  };

  const isPaymentRequired =
    catalogueEnabled &&
    orderInfo.exchange?.paymentMode === 'PREPAID' &&
    totalPayment > 0;

  const onSubmit = ({ payment_id } = { payment_id: null }) => {
    const address = addresses[action.address.select];
    const token = searchParams.get('token');
    const source = searchParams.get('source');
    const redirectionURI = searchParams.get('redirectURI');

    const {
      date,
      shipment_uuid,
      awb,
      reference_number,
      bankDetail,
      storeCredit,
      orderType,
      confirmedRefundPayment
    } = orderInfo;
    const { meta_data } = tracking.theme;
    var query = {
      refund_to_upi: false,
      refund_to_bank_account: false,
      return_info: {
        return_name: address.name || '',
        return_email: address.email || '',
        return_address: address.address || '',
        return_landmark: address.landmark || '',
        return_city: address.city || '',
        return_state: address.state || '',
        return_country: address.country || '',
        return_pin_code: address.pincode || '',
        return_phone_number: address.phone || '',
        return_pickup_date:
          //  address.isSelfShipped
          //   ? null
          //   :
          moment(date).format('YYYY-MM-DD') || '',
        reference_number
      },
      shipment_uuid,
      awb,
      domain: user.subdomain,
      source,
      token,
      self_shipped: address.isSelfShipped,
      return_mode: productList[0].type.toUpperCase()
    };
    if (productList[0].type === 'exchange') {
      query.exchange_items = productList.map(prod => ({
        variant_id: prod.variant_id ?? null,
        quantity: prod.quantity,
        images: prod?.variantImage
          ? prod.variantImage.join(',')
          : prod.images ?? null,
        price: prod.exchangePrice,
        sku: prod.exchangeSku,
        description: prod?.exchangeTitle
          ? prod.exchangeTitle
          : prod.description,
        exchange_type: prod.exchangeType,
        selected_variant: prod.selected_variant?.slice(0, 256) ?? '', // backend supports maximum 256 char
        previous_variant: prod.previous_variant?.slice(0, 256) ?? '',
        item_info_id: Number(prod.id)
      }));

      if (
        catalogueEnabled &&
        (productList.some(
          item => item.exchangeType === exchangeTypes.DIFFERENT_PRODUCT
        ) ||
          Math.abs(totalPayment) > 0 ||
          payment_id)
      ) {
        query.product_wide_exchange = true;
        query.exchange_catalogue_info = {
          order_type:
            Math.sign(totalPayment) <= 0
              ? 'PREPAID'
              : orderInfo.exchange.paymentMode,
          exchange_refund_amount:
            Math.sign(totalPayment) === 0 ? 0 : Math.abs(totalPayment),
          price_difference_result: priceDiffResult[Math.sign(totalPayment)],
          refund_option_code: orderInfo.exchange.refundMode ?? null,
          payment_id: payment_id ?? null
        };
      }
    }
    query.item_info = productList.map(prod => ({
      id: Number(prod.id),
      quantity: prod.quantity,
      reason_id: prod.reasonId.value,
      sub_reason: prod.subreason?.label ?? '',
      return_reason_data: prod.reasonId.label,
      reason_comment: prod.comment ?? '',
      product_image_link:
        file?.[prod.id]?.list?.length > 0
          ? file?.[prod.id].list.map(img => img.s3Url).join(',')
          : ''
    }));

    if (
      storeCredit.allInCredit ||
      (orderType === 'PREPAID' &&
        storeCredit.totalAmountPaid === 0 &&
        storeCredit.totalCredit > 0)
    ) {
      query.return_amount_in_store_credit = true;
    }
    if (
      meta_data &&
      meta_data.capture_bank_detail &&
      confirmedRefundPayment === refundTypeCodes.bankDetails &&
      !(
        source?.toLowerCase() === 'dashboard' &&
        bankDetailNotRequiredForAdminDomains.includes(user.subdomain) &&
        Object.keys(bankFormValidation(bankDetail)).length > 0
      )
    ) {
      query.refund_to_bank_account = true;
      query.bank_detail = {
        name: bankDetail.name,
        account_number: bankDetail.accountNumber,
        ifsccode: bankDetail.ifsccode,
        bank_name: bankDetail.bankName,
        branch_name: bankDetail.branchName
      };
    }

    if (
      confirmedRefundPayment === refundTypeCodes.upiDetails &&
      meta_data &&
      meta_data.capture_upi_detail
    ) {
      query.refund_to_upi = true;
      query.bank_detail = {
        payment_mode: orderInfo.upiDetails.upiName,
        phone_number_vpa: orderInfo.upiDetails.upiId
      };
    }
    if (isExchange && catalogueEnabled && Math.sign(totalPayment) < 0) {
      if (orderInfo.exchange.refundMode === 7) {
        query.refund_to_upi = true;
        query.bank_detail = {
          payment_mode: orderInfo.upiDetails.upiName,
          phone_number_vpa: orderInfo.upiDetails.upiId
        };
      }
      if (orderInfo.exchange.refundMode === 6) {
        query.refund_to_bank_account = true;
        query.bank_detail = {
          name: bankDetail.name,
          account_number: bankDetail.accountNumber,
          ifsccode: bankDetail.ifsccode,
          bank_name: bankDetail.bankName,
          branch_name: bankDetail.branchName
        };
      }
    }

    if (
      confirmedRefundPayment === refundTypeCodes.source &&
      orderType === 'PREPAID' &&
      !noBackToSrcDomains.includes(user.subdomain)
    ) {
      query.back_to_source = true;
    }

    if (
      !isExchange &&
      Object.values(refundTypeCodes).includes(confirmedRefundPayment)
    ) {
      query.refund_option_name = confirmedRefundPayment;
    }

    if (isOnlySummaryScreenDomains.includes(user.subdomain)) {
      query.additional_info = {
        exchange_log_id: productList[0].exchangeLogId,
        newSize: productList[0].selected_variant
      };
    }

    const toastId = toast.success(actionMessages.inProgress);
    return props.returnHandler(query).then(res => {
      if (res.meta && res.meta.success) {
        toast.success(actionMessages.return.submit, { id: toastId });
        const itemInfoIds = query.item_info.map(item => item.id);
        (isOnlySummaryScreenDomains.includes(user.subdomain)
          ? history.replace
          : history.push)(
          `/return-exchange/status?security_key=${
            query.shipment_uuid
          }&waybill=${query.awb}&item_info_id=${itemInfoIds.join(
            ','
          )}&return_id=${res.result.return_id.join(',')}${
            source ? `&source=${source}` : ''
          }${token ? `&token=${token}` : ''}${
            query.selected_variant ? `&newSize=${query.selected_variant}` : ''
          }${redirectionURI ? `&redirectURI=${redirectionURI}` : ''}`
        );
      } else {
        if (res.meta && res.meta.message) {
          toast.error(actionMessages.return.error, { id: toastId });
        } else {
          toast.error(actionMessages.return.error, { id: toastId });
        }
      }
    });
  };

  const onSubmitHandler = e => {
    e.preventDefault();
    state.isSubmitting = true;
    setState({ ...state });
    setShowModal(false);
    setShowPreSubmit(false);
    if (isPaymentRequired) {
      showRazorpay({
        source: 'EXCHANGE_UI',
        amount: Math.abs(totalPayment).toFixed(2),
        options: {
          currency: orderInfo.currency,
          enterprise: user.enterprise,
          description: 'Exchange Request',
          logo: tracking.theme.logo || '/images/logo_placeholder.svg',
          themeColor: tracking.theme?.theme_color,
          prefill: {
            name: orderInfo.addresses?.[action.address.select]?.name,
            email: orderInfo.addresses?.[action.address.select]?.email,
            contact: orderInfo.addresses?.[action.address.select]?.phone
          },
          internal_identifier:
            orderInfo.products.find(
              prod => prod.id === Object.values(orderInfo.request)?.[0]?.id
            )?.tracking_info_id ?? ''
        },
        queryParam: {
          subdomain: user.subdomain,
          waybill: orderInfo.awb,
          security_key: orderInfo.shipment_uuid
        },
        onFailure: () => {
          setShowModal(true);
          setState({ ...state, isSubmitting: false });
        },
        onSuccess: ({ payment_id }) => {
          onSubmit({ payment_id }).then(() =>
            setState({ ...state, isSubmitting: false })
          );
        },
        onOpen: () => setState({ ...state, isSubmitting: false })
      });
    } else {
      onSubmit()?.then(() => setState({ ...state, isSubmitting: false }));
    }
  };
  const onGoBack = () =>
    goBack(
      isExchange && catalogueEnabled ? steps.EXCHANGE_PAYMENTS : steps.REFUND
    );

  const onClose = () => {
    setShowModal(false);
    setShowPreSubmit(false);
  };

  return (
    <form className="ht-100 d-flex flex-col" onSubmit={onSubmitHandler}>
      <div className="mrg-btm-20 mrg-top-20 xs-pd-left-10 xs-pd-right-10">
        {!isOnlySummaryScreenDomains.includes(user.subdomain) && (
          <span
            className={`icon-arrow_back cursor-pointer pull-left mrg-right-10 fs-20`}
            onClick={onGoBack}
          />
        )}
        <span
          className={`fs-20 fw-700 text-capitalize${
            isOnlySummaryScreenDomains.includes(user.subdomain)
              ? ' mrg-left-10'
              : ''
          }`}
        >
          {isExchange ? exchangeTexts[1] : returnTexts[0]} Summary
        </span>
      </div>
      <div className="ht-100 bd-primary bd-1 xs-bd-0 d-flex flex-col overflow-auto overflow-x-hidden justify-between pd-16 exchange-summary-container">
        <div className="row">
          {!isDifferentProductExchange ? (
            <div className="col-md-24 mrg-btm-20 col-xs-24">
              {!isExchange && isExchangeEligible && (
                <Nudge position="TOP" type="summary" className={'mrg-btm-15'} />
              )}
              {/* <div className="fw-600 mrg-btm-5 text-primary-color">
              {isExchange ? 'Exchange ' : 'Return '} Summary
            </div> */}
              <div className="md-flex xs-flex justify-between">
                <div>
                  {productList.map(product => (
                    <div
                      key={product.id}
                      className="md-flex space-y-3 xs-flex text-secondary-color"
                    >
                      <img
                        alt="product"
                        className="mrg-right-10 object-fit-cover img-rounded"
                        width={54}
                        height={54}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = '/images/img-placeholder.svg';
                        }}
                        src={product?.images || '/images/img-placeholder.svg'}
                      />
                      <div
                        className={`d-flex ${
                          isOnlySummaryScreenDomains.includes(user.subdomain)
                            ? ''
                            : 'justify-between '
                        }flex-col`}
                      >
                        <div className="fw-600 fs-16 text-primary-color">
                          {product?.description}
                        </div>
                        {isOnlySummaryScreenDomains.includes(user.subdomain) ? ( // currently doing explicit checks if brand wise customizations required then create a config
                          <>
                            <div>
                              {page.newSize && (
                                <>
                                  <span>
                                    New Size :{' '}
                                    {decodeURIComponent(page.newSize)}{' '}
                                  </span>
                                  <span className="mrg-left-10 mrg-right-10 text-theme">
                                    |
                                  </span>
                                </>
                              )}
                              <span>Qty : 1</span>
                            </div>
                            <div className="fw-600">
                              {orderInfo.currency ??
                                currencyMapper(config.country_code)}{' '}
                              {product?.price}
                            </div>
                          </>
                        ) : (
                          <div>
                            <span className="fw-600">
                              {orderInfo.currency ??
                                currencyMapper(config.country_code)}{' '}
                              {product?.price}
                            </span>
                            <span className="mrg-left-10 mrg-right-10 text-theme">
                              |
                            </span>
                            <span>
                              {isExchange ? exchangeTexts?.[1] : 'Return'} Qty :{' '}
                              {product?.quantity}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>{' '}
                {!isExchange && (
                  <div>
                    <span
                      className="btn-underline"
                      onClick={() => goBack(steps.ITEM_SELECTION)}
                    >
                      Edit
                    </span>
                  </div>
                )}
              </div>
            </div>
          ) : null}
          <div className="col-md-24 mrg-btm-20 col-xs-24">
            <div // used in css
            >
              <div className="d-flex justify-between">
                <span className="fw-600 fs-18 mrg-btm-5 text-primary-color">
                  Address
                </span>

                {config.form_editable &&
                  !isOnlySummaryScreenDomains.includes(user.subdomain) && (
                    <div>
                      <span
                        className="btn-underline"
                        onClick={() => goBack(steps.ADDRESS)}
                      >
                        Edit
                      </span>
                    </div>
                  )}
              </div>
              <div>
                <div className="text-secondary-color">
                  <div className="text-primary-color">
                    {selectedAddress?.name}
                  </div>
                  <div>{selectedAddress?.address}</div>
                  <div>
                    {selectedAddress?.pincode} {selectedAddress?.city}{' '}
                    {selectedAddress?.phone}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!isExchange && (
            <Refund
              summary
              editButton={
                <span
                  className="btn-underline"
                  onClick={() => goBack(steps.REFUND)}
                >
                  Edit
                </span>
              }
            />
          )}

          {meta_data && !meta_data.remove_pickup_date && orderInfo?.date && (
            <div className="col-md-24 mrg-btm-20 col-xs-24">
              <div className="d-flex justify-between">
                <span className="fw-600 fs-18 mrg-btm-5 text-primary-color">
                  Expected Pickup Date
                </span>
                <div>
                  <span
                    className="btn-underline"
                    onClick={() => goBack(steps.DATE)}
                  >
                    Edit
                  </span>
                </div>
              </div>
              <div className="md-flex xs-flex justify-between">
                <div className="text-secondary-color">
                  {moment(orderInfo?.date).format('Do MMMM, YYYY')}
                </div>
              </div>
            </div>
          )}
          {isDifferentProductExchange ? (
            <div className="col-md-24 mrg-btm-20 col-xs-24">
              {productList.map((product, idx) => (
                <React.Fragment key={product.id}>
                  <div key={product.id}>
                    {/* original product */}
                    <div>
                      <div className="fw-600 fs-18 mrg-btm-10 text-primary-color">
                        Original Item {idx + 1} of {productList.length}
                      </div>
                      <div className="d-flex justify-between">
                        <div className="d-flex mrg-btm-10">
                          <img
                            alt="product"
                            className="mrg-right-10 object-fit-cover img-rounded"
                            width={54}
                            height={54}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = '/images/img-placeholder.svg';
                            }}
                            src={
                              product?.images || '/images/img-placeholder.svg'
                            }
                          />
                          <div className="d-flex flex-col justify-between">
                            <div
                              style={{
                                width: '30ch',
                                textOverflow: 'ellipsis'
                              }}
                            >
                              {product?.description}
                            </div>
                            <div>Qty {product?.quantity}</div>
                          </div>
                        </div>

                        <div className="fw-600 text-right whitespace-nowrap d-flex items-center">
                          <span>
                            -
                            {orderInfo.currency ??
                              currencyMapper(config.country_code)}{' '}
                          </span>
                          {product.price_breakup?.discount_percent > 0 ? (
                            <div className="d-flex flex-col">
                              <span className="line-through mx-1">
                                {product?.price?.toFixed(2)}
                              </span>
                              <span className="mx-1">
                                {(
                                  product?.price -
                                  (product.price *
                                    product.price_breakup?.discount_percent) /
                                    100
                                ).toFixed(2)}
                              </span>
                            </div>
                          ) : (
                            product?.price?.toFixed(2)
                          )}
                          <span
                            data-for="minus-tooltip"
                            data-tip="Amount reduced from the total"
                            className="scale-80 mrg-left-5"
                          >
                            {infoIcon(user.subdomain)}
                          </span>
                          <ReactTooltip id="minus-tooltip" />
                        </div>
                      </div>
                    </div>
                    {/* exchange product */}
                    <div className="mrg-top-10">
                      <div className="fw-600 fs-18 mrg-btm-10 text-primary-color">
                        Exchange Item {idx + 1} of {productList.length}
                      </div>
                      <div className="d-flex justify-between">
                        <div className="d-flex">
                          <img
                            alt="product"
                            className="mrg-right-10 object-fit-cover img-rounded"
                            width={54}
                            height={54}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = '/images/img-placeholder.svg';
                            }}
                            src={
                              product?.variantImage?.[0] ||
                              '/images/img-placeholder.svg'
                            }
                          />
                          <div className="d-flex flex-col justify-between">
                            <div
                              style={{
                                width: '30ch',
                                textOverflow: 'ellipsis'
                              }}
                            >
                              {product?.exchangeTitle}
                              <div>{product?.selected_variant}</div>
                            </div>
                            <div>Qty {product?.quantity}</div>
                          </div>
                        </div>

                        <div className="fw-600 text-right whitespace-nowrap d-flex items-center">
                          <span>
                            +
                            {orderInfo.currency ??
                              currencyMapper(config.country_code)}
                          </span>
                          {percentageDiscount &&
                          product.price_breakup?.discount_percent > 0 ? (
                            <div className="d-flex flex-col">
                              <span className="line-through mx-1">
                                {Number(product?.exchangePrice)?.toFixed(2)}
                              </span>{' '}
                              <span className="mx-1">
                                {(
                                  Number(product?.exchangePrice) -
                                  (Number(product.exchangePrice) *
                                    product.price_breakup?.discount_percent) /
                                    100
                                ).toFixed(2)}
                              </span>
                            </div>
                          ) : (
                            Number(product?.exchangePrice)?.toFixed(2)
                          )}
                          {/* {Number(product?.exchangePrice)?.toFixed(2)} */}
                          <span
                            data-for="plus-tooltip"
                            data-tip="Amount added in the total"
                            className="scale-80 mrg-left-5"
                          >
                            {infoIcon(user.subdomain)}
                          </span>
                          <ReactTooltip id="plus-tooltip" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="mrg-btm-0" />
                </React.Fragment>
              ))}
            </div>
          ) : null}
        </div>
        {isExchange &&
          ((exchangeConfig.instruction && !isPreSubmitModal) ||
            catalogueEnabled) && (
            <div className="row">
              <div className="col-md-24 pd-15  col-xs-24">
                {catalogueEnabled ? (
                  <>
                    <div className="bg-grey pd-top-10 pd-btm-10 mrg-btm-10 rounded">
                      {[
                        {
                          label: 'Exchange Fees',
                          prefix: '+',
                          value:
                            exchangeConfig?.catalogueConfig?.[
                              'exchange_request_fees'
                            ]
                        },
                        {
                          label: 'Shipping Fees',
                          prefix: '+',
                          value:
                            exchangeConfig?.catalogueConfig?.[
                              'exchange_shipping_fees'
                            ]
                        },
                        // {
                        //   label: 'Discount Used',
                        //   prefix: '+',
                        //   value: totalDiscountUsed
                        // },
                        {
                          label: 'Shipping Paid',
                          prefix: '-',
                          value: totalShippingPaid
                        }
                      ]
                        .filter(
                          eachPart => eachPart.value > 0
                          // &&
                          //   ((percentageDiscount && i !== 2) ||
                          //     !percentageDiscount)
                        )
                        // .filter(
                        //   eachPart =>
                        //     !(
                        //       // hardcoded value Discount Used
                        //       (
                        //         eachPart.label === 'Discount Used' &&
                        //         forward_order_discount
                        //       )
                        //     )
                        // )
                        .map(eachPart => (
                          <div
                            key={eachPart.label}
                            className="fs-14 fw-600 d-flex justify-between pd-left-10 pd-right-10 mrg-btm-5"
                          >
                            <span className="text-secondary-color">
                              {eachPart.label}
                            </span>
                            <span className="text-secondary-color">
                              {eachPart.prefix} {eachPart.value}
                            </span>
                          </div>
                        ))}
                      <div className="fw-700 d-flex justify-between pd-left-10 pd-right-10 bg-grey pd-5">
                        <span className="fs-18">Total</span>
                        <span>
                          {/* {totalPayment < 0 ? '- ' : ''} */}
                          {orderInfo.currency}{' '}
                          {Math.abs(totalPayment)?.toFixed(2)}
                        </span>
                      </div>
                    </div>
                    <Instructions
                      description={priceDiffInstructions[totalDiffSign]}
                    />
                  </>
                ) : null}
                {exchangeConfig.instruction ? (
                  <div className="bg-brand-primary bd-primary bg-light mrg-top-10 pd-15 md-flex xs-flex rounded">
                    <div>
                      <div className="mrg-right-10">
                        {infoIcon(user.subdomain)}
                      </div>
                    </div>
                    {/* <div> */}
                    {/* <div className="fw-600 mrg-btm-5 text-primary-color">
                  {isExchange ? 'Exchange' : 'Return'} Instructions :
                </div> */}
                    <div className="text-secondary-color">
                      {exchangeConfig.instruction ||
                        'Please note: The concerned pickup address may only be selected from existing registered delivery addresses. New Pickup addresses may not be entered at this time.'}
                    </div>
                    {/* </div> */}
                  </div>
                ) : null}
              </div>
            </div>
          )}
        {!isExchange && udfKeys.returnSummaryInstructions && (
          <Instructions description={udfKeys.returnSummaryInstructions} />
        )}
        {!isExchange && isExchangeEligible && (
          <Nudge position="BOTTOM" type="summary" />
        )}
      </div>
      <div className="row mrg-top-15">
        <div className="col-md-24 pd-left-20 pd-right-20">
          <button
            key="finalSubmit"
            disabled={state.isSubmitting}
            onClick={isPreSubmitModal ? () => setShowPreSubmit(true) : null}
            type={isPreSubmitModal ? 'button' : 'submit'}
            className="ant-button rounded-pill full-width"
          >
            {isPaymentRequired ? 'Make Payment' : 'Submit'}
          </button>
        </div>
      </div>
      <CardOverlay
        className={showPreSubmit ? 'modal-overlay' : 'desktop'}
        modalClassName={showPreSubmit ? 'h-fit' : ''}
        isOpen={showModal || showPreSubmit}
        closeModal={onClose}
        closeButton
      >
        {showModal ? (
          <PaymentFailedModalBody
            onRetry={onSubmitHandler}
            description={
              'Payment failed due to some error, amount will be refunded if it has been debited. Please retry payment to place exchange request.'
            }
          />
        ) : null}
        {showPreSubmit && (
          <>
            <p className="text-primary-color fs-16 mrg-btm-15 mrg-top-15 mrg-right-15">
              {exchangeConfig.instruction}
            </p>
            <button
              type="submit"
              onClick={onSubmitHandler}
              className=" full-width ant-button rounded-pill"
            >
              I Agree
            </button>
          </>
        )}
      </CardOverlay>
    </form>
  );
};

Summary.propTypes = {
  orderInfo: PropTypes.any,
  tracking: PropTypes.any,
  exchangeConfig: PropTypes.object,
  user: PropTypes.any,
  page: PropTypes.any,
  file: PropTypes.any,
  returnHandler: PropTypes.any,
  history: PropTypes.any
};

const mapStateToProps = state => ({
  orderInfo: state.orderInfo,
  tracking: state.tracking,
  exchangeConfig: state.exchangeConfig,
  user: state.user,
  page: state.page,
  file: state.file
});
const mapDispatchToProps = dispatch => ({
  returnHandler: query => dispatch(returnHandler(query))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Summary)
);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { strings } from '../../../utils/localizedStrings';
import { calendarDates } from '../../../utils/entity_mapping';
import { connect } from 'react-redux';
import { dateChange } from '../../../service';
import { useExchangeContext } from '../wrapper';
import { stepActions, steps } from '../defaultValues';
import { infoIcon } from '../utils/svgs';

const DateSelection = ({ user, orderInfo, tracking, ...props }) => {
  const { goForward, goBack, step } = useExchangeContext();
  const dateMeta = calendarDates({ itr: 6, subdomain: user.subdomain }) || [];
  const isExchange = Object.values(orderInfo.request)?.[0].type === 'exchange';
  const { udfKeys } = tracking.theme;
  const catalogueEnabled = props.exchangeConfig.catalogueConfig?.service_active;
  useEffect(() => {
    const { meta_data } = tracking.theme;
    if (
      meta_data &&
      meta_data.remove_pickup_date &&
      step.current === steps.DATE
    ) {
      switch (step.action) {
        case stepActions.FORWARD:
          return goForward(
            isExchange && catalogueEnabled
              ? steps.EXCHANGE_PAYMENTS
              : steps.REFUND
          );
        case stepActions.REVERSE:
          return goBack(steps.ADDRESS);
      }
    }
  }, [step.current === steps.DATE]);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();

        return goForward(
          isExchange && catalogueEnabled
            ? steps.EXCHANGE_PAYMENTS
            : steps.REFUND
        );
      }}
      className="ht-100 d-flex flex-col"
    >
      <div className="mrg-btm-20 mrg-top-20 xs-pd-left-10 xs-pd-right-10">
        <span
          className={`icon-arrow_back cursor-pointer pull-left mrg-right-10 fs-20`}
          onClick={() => goBack(steps.ADDRESS)}
          onKeyDown={() => goBack(steps.ADDRESS)}
          tabIndex={0}
          role="button"
        />
        <span className="fs-20 fw-700 text-capitalize">
          Expected pickup date
        </span>
      </div>
      <div className="bd-primary bd-1 xs-bd-0 flex-col md-flex ht-100 xs-flex justify-between">
        <div className="row pd-15">
          <div className="col-md-24">
            <article className="datepicker-container bd-0 pd-0">
              <span className={`${!orderInfo.date ? 'custom-calendar' : ''}`}>
                <DatePicker
                  className="pick-date"
                  placeholderText={strings[user.lang].selectDate}
                  onChange={props.dateChange}
                  wrapperClassName="full-width"
                  selected={orderInfo.date}
                  dateFormat="dd-MM-yyyy"
                  isClearable={true}
                  includeDates={dateMeta.map(item => new Date(item.date))}
                />
              </span>
            </article>
          </div>
        </div>
        <div className="row pd-15">
          <div className="col-md-24 pd-15  col-xs-24">
            <div className="bg-light pd-15 md-flex xs-flex rounded">
              <div className="mrg-right-10">{infoIcon()}</div>
              <div>
                <div className="fw-600 mrg-btm-5 text-primary-color">
                  {isExchange ? 'Exchange' : 'Return'} Instructions :
                </div>
                <div className="text-secondary-color">
                  {(!isExchange &&
                  orderInfo?.enterpriseChargesConfig?.enable_returns_charges
                    ? orderInfo?.enterpriseChargesConfig?.instructions
                    : null) ??
                    udfKeys.reurnExchangeInstruction ??
                    'Please return the item and packaging in its original condition to avoid pickup cancellation by courier service.'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mrg-top-15 no-gutter">
        <div className="col-md-24 col-xs-24 pd-left-20 pd-right-20">
          <button
            disabled={!orderInfo.date}
            type="submit"
            className="ant-button rounded-pill full-width"
          >
            Next
          </button>
        </div>
      </div>
    </form>
  );
};

DateSelection.propTypes = {
  user: PropTypes.any,
  orderInfo: PropTypes.any,
  tracking: PropTypes.any,
  dateChange: PropTypes.func,
  exchangeConfig: PropTypes.any
};

const mapStateToProps = state => ({
  user: state.user,
  orderInfo: state.orderInfo,
  tracking: state.tracking,
  exchangeConfig: state.exchangeConfig
});
const mapDispatchToProps = dispatch => ({
  dateChange: date => dispatch(dateChange(date))
});

export default connect(mapStateToProps, mapDispatchToProps)(DateSelection);
